<template>
  <v-container fluid class="content-wrapper" style="overflow-y: hidden">
    <div class="content-header row"></div>
    <div class="content-body">
      <!-- Products sell and New Orders -->
      <div class="row match-height">
        <div class="col-12">
          <h4 class="fw-600 mb-2">
            {{ $t("SigninInvoiceAndBank.edit_invoice") }}
          </h4>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <div class="shopping-cart">
            <div class="d-flex flex-row mb-5">
              <button
                class="btn-min-width gl-bt ml-0 mr-3"
                :class="
                  billtoActive ? 'btn-edit-invoice-active' : 'btn-edit-invoice'
                "
                type="button"
                @click="[(billtoActive = true), (editBankDetail = false)]"
              >
                <span>{{ $t("SigninInvoiceAndBank.bill_to") }}</span>
              </button>

              <button
                class="btn-edit-invoice btn-min-width gl-bt mx-0"
                :class="
                  !billtoActive ? 'btn-edit-invoice-active' : 'btn-edit-invoice'
                "
                type="button"
                @click="billtoActive = false"
              >
                <span>{{ $t("SigninInvoiceAndBank.bank_details") }}</span>
              </button>
            </div>
            <div class="tab-content pt-1">
              <div
                v-if="billtoActive"
                class="tab-pane active"
                id="shop-cart-tab"
                aria-labelledby="shopping-cart"
              >
                <div class="row match-height">
                  <div class="col-md-12 col-12">
                    <div class="card">
                      <div class="card-content">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.company") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.name || "Sweet Hotel" }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.address") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{
                                itemInvoiceInfo.address ||
                                "300/4 พรีเมียมเพลส นวมินทร์ ถนนนวมินทร์ แขวงนวมินทร์ เขตบึงกุ่ม"
                              }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>
                                {{ $t("SigninInvoiceAndBank.postal_code") }}
                              </b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.postcode || "10240" }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.city") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ checkProvinceFunc() }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.country") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ checkCountryFunc() }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.tax_id") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{
                                itemInvoiceInfo.tax_number || "xxxxxxxxxxxxx"
                              }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.tel") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.phone || "02-xxx-xxxx" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row match-height">
                  <div class="col-md-12 col-12">
                    <button
                      class="btn btn-info btn-min-width mb-1 gl-bt hover_btn mx-0"
                      type="button"
                      @click="gotoEditInvoiceInfoFunc()"
                    >
                      <span>{{ $t("SignupStep1.btn_edit") }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane active"
                id="checkout-tab"
                v-if="!billtoActive"
              >
                <div
                  v-if="!editBankDetail"
                  class="row match-height"
                  id="bankdetail"
                >
                  <div class="col-md-8 col-12">
                    <div class="card">
                      <div class="card-header-invoice">
                        <h2>
                          {{ $t("SigninInvoiceAndBank.receive_payout_form") }}
                          Aigent
                        </h2>
                        <span>
                          {{ $t("SigninInvoiceAndBank.paid_to_your_bank") }}
                        </span>
                      </div>
                      <hr />
                      <div class="card-content collapse show">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <b>
                                {{ $t("SigninInvoiceAndBank.bank_details") }}
                              </b>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ $t("SigninInvoiceAndBank.title_bank_name") }}
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ checkFullBankNameFunc() }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ $t("SigninInvoiceAndBank.account_number") }}
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ bank_account_number }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6 col-sm-6 col-12">
                              {{
                                $t("SigninInvoiceAndBank.account_holder_name")
                              }}
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ bank_account_name }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ $t("SigninInvoiceAndBank.account_attach") }}
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
                              <v-icon>mdi-paperclip</v-icon>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-md-12 col-12">
                              <button
                                class="btn btn-info btn-min-width mb-1"
                                @click="editBankDetail = true"
                              >
                                {{
                                  $t(
                                    "SigninInvoiceAndBank.btn_edit_bank_details"
                                  )
                                }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row match-height"
                  id="bankedit"
                  v-else-if="editBankDetail"
                >
                  <div class="col-md-12 col-12">
                    <div class="card">
                      <div class="card-header-invoice">
                        <h2>
                          {{ $t("SigninInvoiceAndBank.btn_edit_bank_details") }}
                        </h2>
                        <span>
                          {{ $t("SigninInvoiceAndBank.paid_to_your_bank") }}
                        </span>
                      </div>
                      <div class="card-content collapse show">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <b>
                                {{ $t("SigninInvoiceAndBank.bank_details") }}
                              </b>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div
                              class="col-md-6 col-sm-6 col-12 d-flex flex-column justify-center"
                            >
                              <span>
                                {{ $t("SigninInvoiceAndBank.title_bank_name") }}
                              </span>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12 pb-0">
                              <div
                                class="dropdown select"
                                @click="getfocus('selectbankname')"
                                @mouseleave="mouseOver('selectbankname')"
                              >
                                <a
                                  data-toggle="dropdown"
                                  data-display="static"
                                  id="selectbankname"
                                >
                                  <v-autocomplete
                                    v-model="edit_bankname"
                                    :items="itemsBankName"
                                    item-text="name"
                                    item-value="value"
                                    label
                                    placeholder
                                    :no-data-text="$t('SignupStep.nodata')"
                                    color="pink"
                                    solo
                                    flat
                                    dense
                                    single-line
                                    hide-details
                                    autocomplete="off"
                                    @mouseenter="getfocus('selectbankname')"
                                    @mouseleave="mouseOver('selectbankname')"
                                  ></v-autocomplete>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-12 pt-0"></div>
                            <div class="col-md-6 col-sm-12 col-12 pt-0">
                              <span
                                class="error-text-input"
                                v-if="!edit_bankname"
                                >{{ $t("Alert.pleasefilldata") }}</span
                              >
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div
                              class="col-md-6 col-sm-6 col-12 d-flex flex-column justify-center"
                            >
                              <span>
                                {{ $t("SigninInvoiceAndBank.account_number") }}
                              </span>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12 pb-0">
                              <input
                                id="accountnumber"
                                ref="accountnumber"
                                type="text"
                                class="form-control"
                                name="accountnumber"
                                v-model="edit_account_number"
                                @keypress="isNumber($event)"
                                style="
                                  min-height: 2.625rem;
                                  border: 1px solid var(--border-gray);
                                "
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-12 pt-0"></div>
                            <div class="col-md-6 col-sm-12 col-12 pt-0">
                              <span
                                class="error-text-input"
                                v-if="!edit_account_number"
                                >{{ $t("Alert.pleasefilldata") }}</span
                              >
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div
                              class="col-md-6 col-sm-6 col-12 d-flex flex-column justify-center"
                            >
                              <span>
                                {{ $t("SigninInvoiceAndBank.fullname") }}
                              </span>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12 pb-0">
                              <input
                                id="fullname"
                                ref="fullname"
                                type="text"
                                class="form-control"
                                name="fullname"
                                v-model="edit_fullname"
                                style="
                                  min-height: 2.625rem;
                                  border: 1px solid var(--border-gray);
                                "
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-12 pt-0"></div>
                            <div class="col-md-6 col-sm-12 col-12 pt-0">
                              <span
                                class="error-text-input"
                                v-if="!edit_fullname"
                                >{{ $t("Alert.pleasefilldata") }}</span
                              >
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div
                              class="col-md-6 col-sm-6 col-12 d-flex flex-column justify-center"
                            >
                              <label class for="account_attach">
                                {{ $t("SigninInvoiceAndBank.account_attach") }}
                              </label>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              <div
                                class="dropdown select"
                                @click="getfocus('selectfile')"
                                @mouseleave="mouseOver('selectfile')"
                              >
                                <a
                                  data-toggle="dropdown"
                                  data-display="static"
                                  id="selectfile"
                                  style="height: 3rem !important"
                                >
                                  <v-file-input
                                    v-if="edit_file_account_attach"
                                    v-model="edit_file_account_attach"
                                    accept="application/pdf, .pdf"
                                    show-size
                                    counter
                                    :label="$t('SigninInvoiceAndBank.pp20file')"
                                    color="pink"
                                    solo
                                    flat
                                    dense
                                    single-line
                                    hide-details
                                  ></v-file-input>
                                  <div
                                    v-else
                                    class="d-flex flex-row align-center justify-start"
                                  >
                                    <v-file-input
                                      v-model="edit_file_account_attach"
                                      accept="application/pdf, .pdf"
                                      hide-input
                                      color="pink"
                                      solo
                                      flat
                                      dense
                                      single-line
                                      hide-details
                                    ></v-file-input>
                                    <span>{{ old_select_file }}</span>
                                  </div>
                                </a>
                              </div>
                              <span
                                class="error-text-input"
                                v-if="!old_file_account_attach"
                              >
                                {{ $t("Alert.pleasefilldata") }}
                              </span>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn btn-primary btn-min-width mb-1 mr-1 font-14px"
                                @click="cfSaveEditBankDetailsFunc()"
                              >
                                {{ $t("SigninCalendar.btn_save_change") }}
                              </button>
                              <button
                                type="button"
                                class="btn btn-light btn-min-width mb-1 font-14px"
                                @click="editBankDetail = false"
                              >
                                {{ $t("SigninCalendar.btn_cancel") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card-content collapse show">
                        <div class="card-body">
                          <div class="row mt-2">
                            <div class="col-6 offset-3">
                              <div class="ml-2 mt-1 d-flex flex-wrap">
                                <div class="custom-control custom-radio mr-3">
                                  <input
                                    type="radio"
                                    value="2"
                                    name="type"
                                    required=""
                                    id="type2"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="type2"
                                    >{{
                                      $t("SigninInvoiceAndBank.email")
                                    }}</label
                                  >
                                </div>
                                <div class="custom-control custom-radio mr-3">
                                  <input
                                    type="radio"
                                    value="3"
                                    name="type"
                                    required=""
                                    id="type3"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="type3"
                                    >{{ $t("SigninInvoiceAndBank.sms") }}</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2 text-center">
                            <div class="col-6 offset-3">
                              <input
                                type="text"
                                id="phone"
                                class="form-control"
                                name="phone"
                                placeholder="+66*****5645"
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12 text-center">
                              <button
                                class="btn btn-info"
                                @click="editBankDetail = false"
                              >
                                {{ $t("SigninInvoiceAndBank.btn_ok") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "SigninEditInvoiceScreen",
  metaInfo: {
    title: "Invoice & Bank",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    value_element: "",
    invoiceID: "",
    billtoActive: true,
    editBankDetail: false,
    itemsBankName: [],
    edit_bankname: "",
    edit_account_number: "",
    edit_fullname: "",
    edit_file_account_attach: null,
    displayImg: "",
    itemInvoiceBank: {},
    bank_name: "",
    bank_account_number: "",
    bank_account_name: "",
    bank_attach: null,
    old_file_account_attach: "",
    itemInvoiceInfo: {},
    itemsDefaultCountry: [],
    itemsDefaultProvince: [],
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.invoiceID = self.$route.params.invoiceId;
    self.initAll();
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("focus-2");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.add("focus-2");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.remove("focus-2");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    gotoEditInvoiceInfoFunc(id) {
      const self = this;
      var tmpId = id || self?.invoiceID;
      if (tmpId) {
        var item = {
          link: `finance/invoice_edit_info/${self.userToken}/${self.propertyID}/${tmpId}`,
          name: "SigninEditInvoiceInfoScreen",
          params: {
            userToken: self.userToken,
            propertyId: self.propertyID,
            invoiceId: tmpId,
          },
        };
      } else {
        var item = {
          link: `finance/invoice_edit_info/${self.userToken}/${self.propertyID}`,
          name: "SigninCreateInvoiceInfoScreen",
          params: {
            userToken: self.userToken,
            propertyId: self.propertyID,
          },
        };
      }
      EventBus.$emit("changePathname", item);
    },
    initAll() {
      this.initBankName();
      this.initCountry();
      this.fetchInvoiceInfo();
      this.fetchInvoiceBank();
    },
    initBankName() {
      this.itemsBankName = [
        {
          value: "kbank",
          name: "ธนาคารกสิกรไทย",
        },
        {
          value: "ktb",
          name: "ธนาคารกรุงไทย",
        },
        {
          value: "scb",
          name: "ธนาคารไทยพาณิชย์",
        },
        {
          value: "governmentsavingsbank",
          name: "ธนาคารออมสิน",
        },
        {
          value: "krungsri",
          name: "ธนาคารกรุงศรี",
        },
        {
          value: "bangkok",
          name: "ธนาคารกรุงเทพ",
        },
      ];
      this.itemsBankName.sort((a, b) => a.name.localeCompare(b.name, "th"));
    },
    checkFullBankNameFunc() {
      const self = this;
      const findBank = self?.itemsBankName?.find(
        (p) => p.value == self.bank_name
      );
      return findBank?.name || self.bank_name || "";
    },
    checkCountryFunc() {
      const self = this;
      const findValue = self?.itemsDefaultCountry?.find(
        (p) => p.value == self.itemInvoiceInfo.country
      );
      // console.log(findValue, self.itemsDefaultCountry);
      return findValue?.name || self.itemInvoiceInfo.country || "Thailand";
    },
    checkProvinceFunc() {
      const self = this;
      const findValue = self?.itemsDefaultProvince?.find(
        (p) => p.province_code == self.itemInvoiceInfo.province
      );
      // console.log(findValue);
      return findValue?.province || self.itemInvoiceInfo.province || "Bangkok";
    },
    async initCountry() {
      const self = this;
      var tempCountry = [];
      var temp = [];
      self.itemsDefaultCountry = [];
      self.itemsDefaultProvince = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/setting/country`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map((el) => {
              tempCountry.push({
                _id: el._id || 0,
                name: el.value || "ไทย",
                value: el._id || "th",
                province: el.province || [],
              });
              if (el?.province?.length) {
                el?.province?.map((ele) => {
                  temp.push({
                    district: ele?.subdistrict || "",
                    amphoe: ele?.district || "",
                    province: ele?.province || "",
                    zipcode: ele?.zipcode || "",
                    district_code: ele?.subdistrict_code || "",
                    amphoe_code: ele?.district_code || "",
                    province_code: ele?.province_code || "",
                  });
                });
              }
            });
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsDefaultCountry = tempCountry;
        self.itemsDefaultProvince = temp;
      }
    },
    async fetchInvoiceInfo() {
      const self = this;
      self.itemInvoiceInfo = {};
      var tmpInvoiceInfo = null;

      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/finance/invoice`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          tmpInvoiceInfo = res?.data?.result;
          // console.log(tmpInvoiceInfo);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error);
      } finally {
        self.itemInvoiceInfo = tmpInvoiceInfo;
        self.invoiceID = tmpInvoiceInfo?._id;
        EventBus.$emit("endloading");
      }
    },
    async fetchInvoiceBank() {
      const self = this;
      self.itemInvoiceBank = {};
      var tmpInvoiceBank = null;

      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/finance/invoice/bank`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        // console.log(res);
        if (res.status == 200) {
          tmpInvoiceBank = res?.data?.result;
          self.bank_name =
            tmpInvoiceBank?.bank_name ||
            `<b>SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.</b>
                              <br />
                              <b>0140268</b>
                              <br />MARKET PLACE SUKHAPHIBAN 3
                              <br />SICTHBKXXX`;
          self.bank_account_number = tmpInvoiceBank?.bank_number;
          self.bank_account_name = tmpInvoiceBank?.name;
          self.bank_attach = tmpInvoiceBank?.file;

          self.edit_bankname = tmpInvoiceBank?.bank_name;
          self.edit_account_number = tmpInvoiceBank?.bank_number;
          self.edit_fullname = tmpInvoiceBank?.name;
          self.invoiceID = tmpInvoiceBank?._id;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error);
      }
    },
    alertDimensionLarge() {
      swal(
        this.$t("Alert.title_imgtoolarge"),
        this.$t("Alert.label_imgtoolarge"),
        this.$t("Alert.warn_label"),
        {
          button: false,
          timer: 3000,
        }
      );
    },
    alertSizeLarge() {
      swal(
        this.$t("Alert.title_imgtoolarge"),
        this.$t("Alert.label_imgtoolargesize"),
        this.$t("Alert.warn_label"),
        {
          button: false,
          timer: 3000,
        }
      );
    },
    uploadFileHandler() {
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler(event) {
      const vm = this;
      let img = event.target.files;
      // console.log("img: ", event, img);
      if (img.length > 0) {
        for (let i = 0; i < img.length; i++) {
          const reader = new FileReader();
          reader.onload = (e) => {
            vm.displayImg = e.target.result;
          };
          reader.readAsDataURL(img[i]);
        }

        if (
          img[0]?.width > this.maxImageWidth ||
          img[0].height > this.maxImageHeight
        ) {
          vm.edit_file_account_attach = null;
          vm.displayImg = "";
          vm.alertDimensionLarge();
        } else if (img[0]?.size > 1024 * 1024 * 1 /*1MB*/) {
          vm.edit_file_account_attach = null;
          vm.displayImg = "";
          vm.alertSizeLarge();
        } else {
          vm.edit_file_account_attach = img[0];
        }
      } else {
        this.edit_file_account_attach = null;
      }
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.displayImg = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange(file) {
      // console.log(file);
      if (!file) {
        return;
      } else if (
        file?.width > this.maxImageWidth ||
        file.height > this.maxImageHeight
      ) {
        this.alertDimensionLarge();
        return;
      } else if (file?.size > 1024 * 1024 * 1 /*1MB*/) {
        this.alertSizeLarge();
        return;
      } else if (file !== "undefined") {
        this.edit_file_account_attach = file;
      }
      this.createImage(file);
    },
    isImgEmpty(path) {
      if (path) {
        return path;
      }
      return require("@/assets/icons/icon-noimage.png");
    },
    cfSaveEditBankDetailsFunc() {
      const self = this;
      if (
        !self.edit_bankname ||
        !self.edit_account_number ||
        !self.edit_fullname
      ) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefill")}${
            !self.edit_bankname || self.edit_bankname == ""
              ? ` ${self.$t("SigninInvoiceAndBank.title_bank_name")}`
              : ""
          }${
            !self.edit_account_number || self.edit_account_number == ""
              ? ` ${self.$t("SigninInvoiceAndBank.account_number")}`
              : ""
          }${
            !self.edit_fullname || self.edit_fullname == ""
              ? ` ${self.$t("SigninInvoiceAndBank.fullname")}`
              : ""
          }`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!self.edit_file_account_attach) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefilldata").replace("*", "")}${self.$t(
            "SigninInvoiceAndBank.attach_file"
          )}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        var obj = {
          bank_name: self.edit_bankname,
          bank_number: self.edit_account_number,
          name: self.edit_fullname,
        };
        // console.log("obj: ", obj);
        self.confirmUploadFile(obj);
      }
    },
    async confirmUploadFile(obj) {
      const self = this;
      var filename = null;
      var formData = new FormData();
      formData.append("file", self.edit_file_account_attach);
      EventBus.$emit("loadingtillend");
      // console.log("111confirm");
      if (filename) obj.file = filename || self.edit_file_account_attach.name;
      self.confirmSave(obj, filename);

      var res = null;
      try {
        res = await self.axios.post(
          process.env.VUE_APP_API + `/setting/upload`,
          formData,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        // console.log(res, self.edit_file_account_attach.name);
        if (res.status == 200) {
          filename = self.edit_file_account_attach.name;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error);
      } finally {
        // console.log("confirm");
        if (filename) obj.file = filename || self.edit_file_account_attach.name;
        self.confirmSave(obj, filename);
      }
    },
    async confirmSave(obj, filename) {
      const self = this;
      EventBus.$emit("loadingtillend");
      // console.log("confirm: ", obj);
      if (self.invoiceID) {
        try {
          const res = await self.axios.put(
            process.env.VUE_APP_API +
              `/finance/invoice/bank?invoice_bank_id=${self?.invoiceID}`,
            obj,
            {
              headers: {
                Authorization: self?.userToken,
                "X-Language-Code":
                  localStorage?.getItem("language") ||
                  self?.$store?.state?.language,
              },
            }
          );
          // console.log("update: ", res);
          if (res?.status == 200) {
            EventBus.$emit("endloading");
            swal(
              self.$t("Alert.success_title"),
              self.$t("Alert.save_success"),
              self.$t("Alert.success_label"),
              {
                button: false,
                timer: 3000,
              }
            ).then(() => {
              self.editBankDetail = false;
              self.initAll();
            });
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error);
          swal(
            self.$t("Alert.error_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          EventBus.$emit("endloading");
        }
      } else {
        try {
          const res = await self.axios.post(
            process.env.VUE_APP_API + `/finance/invoice/bank`,
            obj,
            {
              headers: {
                Authorization: self?.userToken,
                "X-Language-Code":
                  localStorage?.getItem("language") ||
                  self?.$store?.state?.language,
              },
            }
          );
          // console.log("new: ", res);
          if (res?.status == 200) {
            EventBus.$emit("endloading");
            swal(
              self.$t("Alert.success_title"),
              self.$t("Alert.save_success"),
              self.$t("Alert.success_label"),
              {
                button: false,
                timer: 3000,
              }
            ).then(() => {
              self.editBankDetail = false;
              self.initAll();
            });
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error);
          swal(
            self.$t("Alert.error_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          EventBus.$emit("endloading");
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/signin/finance.css";
@import "../../../../styles/signin/invoiceandbank.css";
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";
@import "../../../../styles/bootstrap/bootstrap.css";
@import "../../../../styles/bootstrap/colors.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}

.img-thumbnail {
  max-width: 15rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
